nav.nav-main {
    z-index: 1;
    position: sticky;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    margin: 1rem;
    padding: 1rem;
    border-radius: var(--rad-med) var(--rad-med) var(--rad-med) var(--rad-med);
    background-color: var(--clr-purple-primary);
    color: white;
    box-shadow: var(--shadow-med);
    display: grid;
    row-gap: 1rem;
    column-gap: 1rem;
}

span.logo-combo {
    justify-self: start;
    color: white;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    column-gap: 0.4rem;
    padding: 0.5rem 0.7rem;
    border-radius: var(--rad-sml) var(--rad-sml) var(--rad-sml) var(--rad-sml);
}

img.logo-icon {
    width: 2rem;
    padding-bottom: 0.2em;
}

span.tray-notif-avatar {
    grid-column: 3 / 4;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    column-gap: 1rem;
    color: var(--clr-purple-primary);
}

a.btn-nav-auth {
    margin-right: 0.5rem;
}

button.btn-notif {
    flex-shrink: 0;
    padding: 0.5rem;
    border-radius: 50%;
}

button.btn-avatar {
    background-color: var(--clr-green-light);
    border-radius: var(--rad-sml) var(--rad-sml) var(--rad-sml) var(--rad-sml);
}

button.btn-avatar:hover span.avatar-slideout img,
button.btn-avatar:focus span.avatar-slideout img {
    transform: translateY(0.2rem);
}

nav.nav-main span.avatar-container {
    width: 3rem;
    height: 3rem;
}

span.avatar-container {
    overflow: hidden;
    border-radius: inherit;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

span.avatar-container img.avatar {
    object-fit: cover;
    object-position: center;
    max-width: inherit;
    max-height: inherit;
    height: inherit;
    width: inherit;
    background: inherit;
}

span.avatar-slideout {
    height: 3rem;
    width: 1.2rem;
    padding: 0.1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--clr-green-dark);
}

span.avatar-slideout img {
    max-width: 100%;
    transition: transform var(--tns-hov-quick);
}

/* || Popup Menus */

div.popup {
    display: flex;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 100%;
    right: 0;
    min-width: 6rem;
    max-width: 90vw;
    margin: 0.5rem auto auto auto;
    padding: 0;
    border: 1px var(--clr-gray-light) solid;
    border-radius: var(--rad-sml) var(--rad-sml) var(--rad-sml) var(--rad-sml);
    overflow: hidden;
    background-color: white;
    box-shadow: var(--shadow-med);
    transition: opacity var(--tns-hov-quick);
}
@media screen and (max-width: 45rem) {
    div.popup-drawer-scrollable {
        right: -5rem;
    }
}
div.popup ul li {
    border-bottom: 1px var(--clr-gray-light) solid;
    transition: background-color var(--tns-hov-quick);
}
div.popup ul li:last-child {
    border-bottom: none;
}
div.popup ul li:hover,
div.popup ul li:focus {
    background-color: var(--clr-gray-light);
}

/* Supply this class to the parent of the popup */
.popup-parent {
    position: relative;
}
.popup-parent::after {
    /* prevents cursor from falling through the margin */
    content: "";
    position: absolute;
    width: 130%;
    bottom: -1rem;
    height: 1rem;
    background: transparent;
}
.popup-parent:hover div.popup,
.popup-parent:focus div.popup {
    visibility: visible;
    opacity: 1;
}

/* Popup menus with fixed number of elements; chain with .popup */
div.popup-fixed ul {
    width: max-content;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: flex-start;
}
div.popup-fixed ul li > * {
    width: 100%;
    text-align: left;
    padding: 0.5rem;
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    column-gap: 0.5rem;
}
div.popup-fixed ul li img {
    max-height: 1.2em;
}

/* Popup menus with arbitrary number of elements, fixed max-height, scrollable; 
 * chain with .popup
 */
div.popup-drawer-scrollable {
    width: 20rem;
    max-height: 25rem;
    overflow-y: auto;
}

div.popup-drawer-scrollable > * {
    width: 100%;
}

/* || Notification Preview Strip */

div.notif-strip {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    row-gap: 0.5rem;
    padding: 0.5rem;
}

div.notif-strip h4,
div.notif-strip p.notif-preview {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

div.notif-strip h4 {
    white-space: nowrap;
}

div.notif-strip span.notif-time {
    position: relative;
    background-color: var(--clr-gray-mid-light);
    border-radius: var(--rad-tny) var(--rad-tny) var(--rad-tny) var(--rad-tny);
    padding: 0.2rem 0.4rem;
    font-size: var(--font-size-sml);
}

div.notif-strip-unread span.notif-time {
    background-color: var(--clr-green-light);
}
div.notif-strip-unread span.notif-time::after {
    /* creates a red circle for unread notification */
    content: "";
    position: absolute;
    top: -0.2rem;
    right: -0.2rem;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: var(--clr-red-important);
}

div.notif-strip p.notif-preview {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    white-space: normal;
    word-break: break-all;
    margin-bottom: 0;
    max-height: 6rem;
    text-align: left;
}
