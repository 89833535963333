.search-bar {
    display: flex;
    border: 2px solid white;
    border-radius: 2rem;
    margin: 0.25rem 0;
    padding: 0;
    height: 3rem;
    align-self: center;
    justify-self: center;
    width: 100%;
    max-width: 45rem;
    overflow: hidden;
}

@media screen and (max-width: 45rem) {
    .search-bar {
        grid-row: 2;
        grid-column: 1 / 4;
        justify-self: center;
    }
}

.search-bar input {
    flex: 1 0;
    border: none;
    outline: none;
    margin: 0;
    padding: 0.25rem 1rem;
    color: white;
    background-color: transparent;
}

.search-bar button {
    border: none;
    border-radius: 1.95rem;
    min-width: 4rem;
    background-color: white;
    margin: 0;
    padding: 0.25rem 0.5rem;
    color: var(--clr-purple-bg);
}

.search-bar button:hover {
    background-color: var(--clr-purple-light);
}
