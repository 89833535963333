/* || Common Modal Components*/

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.modal-container {
    width: fit-content;
    max-width: 58rem;
    height: 80vh;
    overflow-y: auto;
    border-radius: var(--rad-med);
    background-color: white;
    box-shadow: var(--shadow-lrg);
    display: flex;
    flex-wrap: wrap-reverse;
    flex-direction: row;
    z-index: 3;
}

/* Main Modal Content Styles */

section.modal-content {
    flex: 2 0;
    min-width: 24rem;
    height: inherit;
    margin-right: -2rem;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    z-index: 4;
}

section.modal-content .image-section {
    flex-grow: 1;
    background-color: var(--clr-gray-mid-dark);
    color: white;
    padding-right: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

section.modal-content .info-section {
    padding-right: 4rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.info-section article.property-info {
    margin: 0 2rem 1rem 2rem;
    display: flex;
    flex-direction: column;
}

/* Action Sidebar Styles */

.property-info h3 {
    color: var(--clr-purple-primary);
}

aside.modal-action {
    flex: 1 0;
    min-width: 22rem;

    margin-left: -2rem;
    height: inherit;
    background-color: white;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    box-shadow: var(--shadow-med-left);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    overflow: hidden;
    z-index: 5;
}
aside.modal-action.with-action-widget {
    overflow: hidden auto;
}
@media screen and (max-width: 46rem) {
    section.modal-content {
        height: auto;
        margin-right: unset;
    }
    section.modal-content .image-section {
        padding-right: unset;
    }
    section.modal-content .info-section {
        padding-right: unset;
    }
    aside.modal-action {
        margin-left: 0;
        height: auto;
        box-shadow: unset;
    }
}

aside.modal-action button.btn-modal-close,
button.del-img {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    z-index: 6;
    padding: 0.5rem;
    border-radius: 50%;
}
aside.modal-action button.btn-modal-close:hover,
button.del-img:hover {
    background-color: var(--clr-red-important);
}

aside.modal-action .modal-header {
    flex-shrink: 0;
    width: 100%;
    min-height: 6.5rem;
    padding: 2rem;
    background-color: var(--clr-purple-primary);
    color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-evenly;
}
aside.modal-action.with-action-widget .modal-header {
    padding-bottom: 8rem;
}

aside.modal-action .item-container {
    flex: 1;
    overflow-y: auto;
    padding: 1rem 0 2rem 0;
}
aside.modal-action.with-action-widget .item-container {
    overflow-y: visible;
}

/* Action Widget (Green) Styles */

div.action-widget {
    background-color: var(--clr-green-bg);
    color: var(--clr-gray-dark);
    width: 90%;
    padding: 1rem;
    border-radius: var(--rad-med);
    box-shadow: var(--shadow-med);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    row-gap: 0.5rem;
    position: relative;
    top: -7rem;
    margin-bottom: -7rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 6;
}

div.action-widget * {
    max-width: 100%;
}

div.action-widget h3 {
    padding-bottom: 0.5rem;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    column-gap: 0.5rem;
}

div.action-widget h4 {
    margin-top: 0.5rem;
}

aside.modal-action article.action-details {
    margin: 2rem 1rem;
}

aside.modal-action article.action-details h4 {
    margin-bottom: 1rem;
    color: var(--clr-purple-primary);
}

div.action-widget button.action-btn {
    margin-top: 1rem;
}

button.action-btn.create-listing {
    position: absolute;
    bottom: 1rem;
    right: 1.5rem;
    width: 4rem;
    height: 4rem;
    border-radius: var(--rad-med);
    box-shadow: var(--shadow-sml);
    overflow: hidden;
    z-index: 7;
    font-size: 3rem;
    line-height: 100%;
    text-align: center;
}
button.action-btn.create-listing:hover {
    box-shadow: var(--shadow-med);
}
button.action-btn.create-listing img {
    width: inherit;
    height: auto;
    min-width: none;
    min-height: auto;
}
