button.btn-map {
    height: 33px;
    background-image: url("/src/assets/icons/map-pin.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    width: 45px;
    background-color: var(--clr-gray-dark);
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    border: none;
}
