/* || Host: Property Creation Modal */

aside.modal-action .input-container {
    display: flex;
    flex-direction: column;
    margin: 1rem;
    row-gap: 0.25rem;
}

section.modal-content .image-selector {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content;
    justify-items: center;
    gap: 1.5rem;
    padding: 4.5rem 3.5rem;
    overflow: auto;
}

.image-selector img.property-img {
    width: 14rem;
    height: 8.5rem;
}

.pos-relative {
    position: relative;
}

.modal-content h3 {
    margin-top: 1.5rem;
}

button.send-arrow {
    padding: 0.5rem;
    border-radius: 1.5rem;
}

.input-container .availability-item input {
    width: 100%;
}

@media screen and (max-width: 860px) {
    .modal-content .image-selector {
        grid-template-columns: 1fr;
    }
    .image-selector img {
        height: 100%;
        width: 100%;
    }
}

@media screen and (max-width: 640px) {
    .modal-content .image-selector {
        height: fit-content;
        overflow: visible;
        padding: 1.5rem;
    }
}

/* Host: Existing Property Modal */

h3.request-heading {
    color: var(--clr-purple-primary);
    margin-top: 1.5rem;
}

.modal-action .request-card {
    height: fit-content;
    width: 100%;
    border: 2px solid var(--clr-gray-light);
    border-radius: var(--rad-sml);
    padding: 0.5rem 1rem 0.5rem 1rem;
    box-shadow: var(--shadow-sml);
}

.request-card.host-comment {
    margin-top: 0.5rem;
    margin-left: auto;
    width: 80%;
    background-color: var(--clr-gray-light);
}

.host-comment .profile-img {
    margin-left: 0;
}

.request-card-content {
    padding: 0.5rem 0;
    display: flex;
    align-items: stretch;
}

.request-card-info {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
}

.request-card-info h4 {
    margin-bottom: 0.5em;
}

.request-card-info p {
    margin: 0;
}

.request-card-info p.price {
    color: var(--clr-purple-primary);
    font-weight: 700;
}

.rating-container {
    display: flex;
}

.request-card .btn-container {
    display: flex;
    justify-content: space-between;
}

.request-card .action-btn {
    border-radius: var(--rad-med);
    padding: 0.3rem 1rem;
    color: var(--clr-gray-mid-dark);
    font-weight: 700;
}

.request-card .action-btn.gray-light {
    background-color: var(--clr-gray-light);
}

.request-card .user-name {
    font-size: var(--font-size-reg);
}

img.profile-img {
    height: 3rem;
    width: 3rem;
    border-radius: 3rem;
    margin: 0.5rem 1rem 0.5rem 1rem;
    margin-left: 1rem;
}

.request-container {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-block: 0.5rem;
}

.request-container summary.comments-dropdown {
    background-color: white;
    /* border: 2px solid var(--clr-gray-light); */
    box-shadow: var(--shadow-sml);
}

textarea.description-text {
    width: 100%;
    height: 10rem;
    resize: none;
    border: 2px solid var(--clr-gray-dark);
    border-radius: var(--rad-sml);
    padding: 0.5rem;
}

details.reply-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.comment-card {
    background-color: var(--clr-purple-bg);
    border-radius: var(--rad-med);
    display: flex;
    align-items: flex-start;
    padding: 0.5rem 0.25rem;
}

.comment-card .comment-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: fit-content;
    margin-top: 1rem;
    padding-right: 1rem;
}

.comment-card.main-comment .profile-img {
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
}

span.date {
    color: var(--clr-green-dark);
}

span.price {
    color: var(--clr-purple-primary);
}

.reply-input-container {
    display: flex;
}

.reply-input-container textarea {
    width: 100%;
    border-radius: var(--rad-sml);
    resize: none;
    padding: 0.5rem;
}

.comment-card.reply {
    width: 80%;
    margin-left: auto;
    margin-bottom: 0.5rem;
}

.comment-card.reply .profile-img {
    height: 4rem;
    width: 4rem;
    border-radius: 4rem;
}

.reply.host-reply {
    background-color: var(--clr-gray-light);
}

li .past-guest-card {
    min-width: 14rem;
    background-color: var(--clr-purple-bg);
    border-radius: var(--rad-med);
    display: flex;
    flex-direction: column;
    padding: 0 0.5rem 0.5rem 1rem;
}

.past-guest-card .guest-info {
    display: flex;
    align-items: center;
}

.past-guest-card .rating-container {
    padding: 0 0 0.5rem 0.5rem;
}

.past-guest-card .profile-img {
    margin-left: 0;
}

.past-guest-card.has-ratings,
.request-card.has-ratings,
.comment-card.has-ratings {
    border: none;
    border-radius: var(--rad-med) var(--rad-med) var(--rad-med) 0;
}

summary.comments-dropdown {
    background-color: var(--clr-purple-bg);
    width: fit-content;
    padding: 0.5rem 1rem;
    text-align: center;
    list-style-type: none;
    border-radius: 0 0 var(--rad-med) var(--rad-med);
    font-weight: 700;
    cursor: pointer;
}

details[open] summary.comments-dropdown::after {
    content: attr(comments-open);
}

details:not([open]) summary.comments-dropdown::after {
    content: attr(comments-hidden);
}

ul.content-list {
    max-height: 30rem;
    overflow-y: auto;
}
ul.content-list.content-list-comments {
    margin-top: 0.5rem;
    box-shadow: var(--shadow-sml);
    border: 1px solid var(--clr-gray-light);
    border-radius: var(--rad-med);
    padding: 0.5rem;
}

.content-list li {
    margin: 1rem 0 1rem 0;
}

div.amenity-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: space-around;
}

.amenity-container .amenity-tag {
    border: 2px solid var(--clr-gray-dark);
    padding: 0.25rem 0.5rem;
    border-radius: var(--rad-med);
    font-weight: 700;
    margin: 0 1rem 0.5rem 0;
    display: flex;
    align-items: center;
}

.amenity-tag .action-btn {
    padding: 0;
    margin-right: 0.5rem;
}

.availability ul.content-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;
}

.availability-item {
    display: flex;
    flex-direction: column;
}

.availability-item p {
    margin: 0;
    font-weight: 700;
}

.availability-item.card {
    border: 2px solid var(--clr-gray-light);
    border-radius: var(--rad-med);
    padding: 1rem 2.5rem 1rem 1rem;
}

form.add-availability {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 0.5rem;
    width: min-content;
}

ul.availabilityErrors {
    box-sizing: border-box;
    list-style-type: disc;
    width: 100%;
    text-align: left;
    font-size: 0.9em;
    color: var(--clr-red-important);
    margin-bottom: 0.5rem;
}

.availability-item button.action-btn,
.add-availability button.action-btn {
    margin-right: 0;
}

div.save-changes {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.pos-relative button {
    position: absolute;
    z-index: 6;
    padding: 0.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pos-relative button.del-img {
    top: -1.2rem;
    right: -1.2rem;
    background-color: var(--clr-red-semiopaque);
}

.pos-relative button.del-item {
    top: 0.1rem;
    right: 0.1rem;
}

.pos-relative button.del-img:hover,
.pos-relative button.del-item:hover {
    background-color: var(--clr-red-important);
}

span.field-required {
    color: var(--clr-red-important);
    font-weight: bolder;
}

.property-info h3 {
    margin-bottom: 0.5rem;
}
