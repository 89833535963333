/* css styles for auth */
div.auth.container {
    margin: 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    justify-content: space-evenly;
    align-items: flex-end;
    row-gap: 3rem;
    column-gap: 2rem;
}
.signup-m {
    display: none;
}

div.sidebar {
    padding: 1rem;
    max-width: 21rem;
    height: 20rem;
    background-color: var(--clr-purple-primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 1rem;
    position: sticky;
    top: 9rem;
    margin-top: 0.5rem;
}

div.sidebar h2,
div.sidebar h3 {
    color: white;
}

.auth-form {
    height: fit-content;
    color: var(--clr-gray-dark);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    row-gap: 1rem;
}
a.forgot {
    text-decoration: underline;
}
.red {
    color: var(--clr-red-important);
    background-color: transparent;
}
input {
    width: 100%;
    border: 1px solid #cdcdcd;
}
.auth-form .action-btn {
    margin-left: 30%;
    width: 40%;
    border: 1px solid var(--clr-green-dark);
}
.auth input[type="text"].rounded-r {
    height: 36.5px;
    padding: 0.5rem 1rem;
    border: 1px solid #cdcdcd;
}
p.error {
    color: var(--clr-red-important);
    margin: 0;
    text-align: center;
    max-width: 21rem;
}
