.filter-inputs {
    display: flex;
    width: 100%;
}

button.btn-left {
    font-size: 0.8rem;
    text-align: center;
    color: white;
    height: 33px;
    width: 4rem;
    padding: 1rem 0;
    background-color: var(--clr-gray-dark);
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    border: none;
}

input[type="date"].flat {
    position: relative;
    border: 2px solid var(--clr-gray-dark);
    border-left: none;
    background-color: transparent;
    width: 100%;
    border-radius: 0%;
    padding: unset;
    padding-left: 10px;
    height: 33px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}
.flat::placeholder {
    color: black;
}
