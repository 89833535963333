/* css styles for home */
div.container-home {
    display: flex;
    flex-direction: row!important;
    justify-content: flex-start!important;
    flex-wrap: nowrap!important;
    margin: 2rem!important;
    padding: 2rem!important;
    padding-left: 0!important;
    padding-right: 0!important;
    margin-left: 0!important;
    margin-right: 0!important;
    width: 100%;
}
.sort-home-m {
    display: none;
}

input[type="text"].search-mb {
    display: none;
}

.flat1 {
    padding-left: 22.5%;
}

.flat.center {
    text-align: center;
}

aside.sidebar {
    height: fit-content;
}
.sidebar-content {
    padding: 0.5 rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 1rem;
}
.amenities {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}
.calendar {
    width: 95%;
    background-image: url("../../assets/icons/calendar.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
}
.btn-increment {
    width: 50%;
}
.btn-decrement {
    width: 50%;
}
.btn-amenities {
    flex: 0 0 30%;
    margin-bottom: 4px;
}
button.action-btn {
    font-size: 16px;
    height: auto;
    padding: 0.5rem;
}

.price-icons {
    margin-right: 5px;
    margin-left: 5px;
    width: 35%;
}

div.home-content {
    margin: 2rem 0;
}

.property_card {
    width: 30%;
}

.home-cards {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    column-gap: 2rem;
    font-size: var(--font-size-sml);
}

/* Style for the dropdown container */
.dropdown {
    position: relative;
    display: inline-block;
  }

.dropdown_list {
    display: block;
    position: absolute;
    inset: 0px auto auto 0px;
    transform: translate3d(0px, 40px, 0px);
    z-index: 1000;
    min-width: 10rem;
    padding: 0.5rem 1rem;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: white;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.175);
    border-radius: 0.375rem; 
  }
  
  /* Style for the dropbtn (the button that triggers the dropdown) */
  .dropdown_button::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  
  .dropdown_item {
    display: block;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
  }

  .dropdown_item:hover {
    background-color: aliceblue;
  }
  



@media only screen and (max-width: 610px) {
    /* For mobile phones and tablets: */

    aside.sidebar {
        display: none;
    }

    .sort-home-m {
        display: flex;
        justify-content: space-evenly;
    }
    .home-cards {
        justify-content: space-evenly;
    }
    button.action-btn {
        margin: 0;
        padding-right: 1rem;
    }
    .sort-home {
        display: none;
    }
}