button.btn-price {
    background-color: var(--clr-gray-dark);
    background-image: url("/src/assets/icons/dollar-sign.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 50%;
    width: 45px;
    height: 33px;
    padding: 1rem;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    border: none;
}
